import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Login from "../components/Login"
import PageTitle from "../components/PageTitle";

export default ({location}) => (
  <Layout>
    <SEO title="ログイン"/>
    <section className="inner">
      <PageTitle>ログイン</PageTitle>
      <Login location={location}/>
    </section>
  </Layout>
)