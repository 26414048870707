import React, {useState, useContext} from "react"
import {useForm} from "react-hook-form"
import _ from "lodash"
import {AppSync} from "../../libraries/appsync";
import Cookies from "js-cookie"
import qs from "qs"
import Button from "../Button"
import AuthContext from "../Context/AuthContext"
import classNames from "classnames";
import {navigate} from "gatsby";
import modStyle from "./index.module.css";
import eyeOpenImg from "../../images/Login/eye-open.svg";
import eyeCloseImg from "../../images/Login/eye-close.svg";

export default ({location}) => {
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const {register, errors, handleSubmit} = useForm();
  const {updateSession} = useContext(AuthContext)
  const [pwInputType, setPwInputType] = useState('password')
  const [pwEyeImg, setPwEyeImg] = useState(eyeOpenImg)

  const formLogin = (data, e) => {
    e.preventDefault()

    setLoading(true)
    AppSync.post("/", {
      query: `
        query loginTapirsUser {
          loginTapirsUser(email: "${_.get(data, "email")}", password: "${_.get(data, "password")}") {
            personal_c
            member_type
            sess
          }
        }
      `
    }).then(res => {
      if (_.has(res.data, "errors")) {
        setApiError("ユーザーが存在しない、またはパスワードが一致しません。")
        setLoading(false)
        return
      }

      if (_.isEmpty(_.get(res.data, "data.loginTapirsUser.sess"))) {
        setApiError("ログインできませんでした。")
        setLoading(false)
        return
      }

      typeof window !== "undefined" && window.localStorage.setItem("personal", JSON.stringify(_.get(res.data, "data.loginTapirsUser")))
      Cookies.set(process.env.STORE_SESSION_NAME, _.get(res.data, "data.loginTapirsUser.sess"), {expires: 1, domain: process.env.STORE_SESSION_DOMAIN})
      updateSession()

      const queryString = qs.parse(_.get(location, "search"), {ignoreQueryPrefix: true})
      if (_.get(queryString, "continue")) {
        window.location.href = `${process.env.MODD_LOGOUT_URL}&ReturnUrl=${encodeURIComponent(_.get(queryString, "continue"))}`
      } else {
        window.location.href = `${process.env.MODD_LOGOUT_URL}&ReturnUrl=${encodeURIComponent(process.env.MODD_LOGIN_CONTINUE_URL)}`
      }
    })
  }

  const formErrorClass = validateResult => validateResult ? "error" : ""

  const changePasswordView = (_type, _img) => {
    setPwInputType(_type)
    setPwEyeImg(_img)
  }

  const showHidePassword = () => {
    pwInputType === "password"
      ? changePasswordView("text", eyeCloseImg)
      : changePasswordView("password", eyeOpenImg)
  }

  return (
    <div className={classNames({loading: loading}, "container", "bg-white", "inner", "form")}>
      <div>
        {!_.isEmpty(apiError) && <p className="comment error">{apiError}</p>}

        <form onSubmit={handleSubmit(formLogin)}>
          <div className="field">
            <label htmlFor="email">ログインID（メールアドレス）</label>
            <input type="email" name="email" id="email" ref={register({required: true, pattern: /[^\s]+@[^\s]+/})} className={formErrorClass(errors.email)} />
            {errors.email && <p className="comment error">正しいメールアドレスを入力してください</p>}
          </div>
          <div className="field">
            <label htmlFor="password">パスワード</label>
            <div className={modStyle.pwField}>
              <input type={pwInputType} name="password" id="password" ref={register({required: true})} className={classNames(formErrorClass(errors.password), modStyle.pwInputBox)} />
              <span className={modStyle.pwShowHide} onClick={showHidePassword}>
                <img src={pwEyeImg} alt="" className={modStyle.pwEyeImg} />
              </span>
            </div>
            {errors.password && <p className="comment error">パスワードを入力してください</p>}
          </div>
          <p className="margin right">※半角の英字と数字を混ぜた8～16文字</p>
          <div className="submit">
            <Button type="submit">ログイン</Button>
          </div>
          <p className="margin center">※ パスワードを忘れた方は<a href={process.env.FANCLUB_URL + 'email_validation_pwreset.php'} target="_blank" rel="noreferrer" className="underline">こちら</a></p>
        </form>
      </div>
      <div className={classNames("container", "bg-gray", "inner", "form", "contents-space")}>
        <div>
          <h3>はじめてご利用の方</h3>
          <p className="explain">アカウント登録がお済みでない方は、アカウントの作成が必要となります。<br/>「アカウント作成」からお手続きをお願いいたします。</p>
          <form>
            <div className="submit">
              <button type="button" className="button-module--button--1AP7e" onClick={() => navigate(process.env.FANCLUB_URL + 'email_validation.php')}>アカウント作成</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
